import React from "react"
import { css } from "@emotion/core"
import { md, lg } from "./layout"
import Input from "./input"
import Button from "./button"
import contact from "../images/contact.svg"
import emailIcon from "../images/at-sign.svg"
import nameIcon from "../images/menu.svg"
import messageIcon from "../images/message-circle.svg"

const Contact = () => {
  return (
    <section
      id="contact"
      css={css`
        background-color: var(--black);
        padding-top: 100px;
        padding-bottom: 100px;
        margin-top: 350px;
      `}
    >
      <div className="container">
        <h1
          id="contact-title"
          css={css`
            font-size: 56px;       
            margin: 0 0 1em;
            text-align: center;
            color: var(--whiter);
            background-image: url('${contact}');
            background-repeat: no-repeat;
            background-position: center;

            @media (min-width: ${md}) {
              font-size: 118px;
            }
        `}
        >
          contact
        </h1>
        <form
          name="contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          css={css`
            display: grid;
            justify-content: center;
            grid-template-columns: 1fr;
            gap: 16px;

            @media (min-width: ${lg}) {
              grid-template-columns: repeat(2, 1fr);
            }
          `}
        >
          <input
            aria-hidden="true"
            aria-labelledby="contact-title"
            type="hidden"
            name="form-name"
            value="contact"
          />
          <Input icon={emailIcon} name="email" label="email" type="text" />
          <Input icon={nameIcon} name="name" label="name" type="text" />
          <div
            css={css`
              grid-column: 1/-1;
            `}
          >
            <Input
              icon={messageIcon}
              name="message"
              label="message"
              type="textarea"
            />
          </div>
          <div
            css={css`
              grid-column: 1/-1;
              margin-top: 2rem;
              display: flex;
              align-items: center;
              justify-content: center;

              button {
                width: max-content;
                padding: 10px 50px;
              }
            `}
          >
            <Button variant="white">send</Button>
          </div>
        </form>
      </div>
    </section>
  )
}

export default Contact
