import React from "react"
import { css } from "@emotion/core"
import { md, lg } from "./layout"
import Button from "./button"

const Services = () => {
  return (
    <section id="services">
      <div className="container">
        <div
          css={css`
            border-radius: 10px;
            box-shadow: 10px 15px var(--brown);
            @media (min-width: ${md}) {
              box-shadow: 20px 20px var(--brown);
            }
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0 32px;
              height: 144px;
              background-color: var(--orange);
              border-radius: 10px 10px 0 0;
              @media (min-width: ${md}) {
                justify-content: flex-start;
              }
            `}
          >
            <h1
              css={css`
                margin: 0;
                font-size: 56px;

                @media (min-width: ${md}) {
                  font-size: 118px;
                }
              `}
            >
              services
            </h1>
          </div>
          <div
            css={css`
              border-radius: 0 0 10px 10px;
              background-color: var(--white);
              display: grid;
              grid-template-columns: 1fr;
              gap: 90px 150px;
              padding: 20px 60px;
              justify-content: center;
              align-items: flex-start;

              @media (min-width: ${lg}) {
                grid-template-columns: repeat(2, 1fr);
                padding: 60px;
              }
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
              `}
            >
              <h5
                css={css`
                  text-decoration: underline;
                  font-size: 50px;
                  margin-bottom: 50px;
                  margin-top: 0;
                `}
              >
                design
              </h5>
              <p
                css={css`
                  font-size: 30px;
                  margin: 0;
                  text-align: center;
                `}
              >
                We can provide a logo & a web design or use an alrady existing
                one.
              </p>
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
              `}
            >
              <h5
                css={css`
                  text-decoration: underline;
                  font-size: 50px;
                  margin-bottom: 50px;
                  margin-top: 0;
                `}
              >
                development
              </h5>
              <p
                css={css`
                  font-size: 30px;
                  margin: 0;
                  text-align: center;
                `}
              >
                We carry out pixel perfect designs and provide fast,
                customizable websites.
              </p>
            </div>
            <div
              css={css`
                grid-column: 1/-1;
              `}
            >
              <Button>check our latest project!</Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Services
