import { useState, useRef, useEffect } from "react"
import lottie from "lottie-web"

export default function useLottie({
  animation,
  autoplay,
  loop,
  renderer = "svg",
}) {
  const containerRef = useRef(null)
  const [anim, setAnim] = useState()

  useEffect(() => {
    setAnim(
      lottie.loadAnimation({
        container: containerRef.current,
        renderer: "svg",
        loop,
        autoplay,
        animationData: animation,
      })
    )

    return () => anim.destroy() // optional clean up for unmounting
    // eslint-disable-next-line
  }, [])

  return [anim, containerRef]
}
