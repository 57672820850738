import { graphql, useStaticQuery } from "gatsby"

export default function useClients() {
  const clients = useStaticQuery(graphql`
    query Clients {
      allMarkdownRemark(filter: { fileAbsolutePath: {} }) {
        nodes {
          frontmatter {
            description
            slug
            title
          }
          html
        }
      }
    }
  `)

  return clients.allMarkdownRemark.nodes.map((node) => ({
    ...node.frontmatter,
    ...node,
  }))
}
