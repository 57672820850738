import React from "react"
import { css } from "@emotion/core"
import splatter from "../images/Splatter.svg"
import { md, lg } from "./layout"
import Slider from "./slider"
import useClients from "../hooks/use-clients"

const Clients = () => {
  const clients = useClients()
  return (
    <section
      id="clients"
      css={css`
        padding: 100px 0;
        margin: 150px 0;
        position: relative;
      `}
    >
      <div className="container">
        <img
          src={splatter}
          alt="Background Splat."
          css={css`
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -40%);
            z-index: -1;
            width: 180vw;
            @media (min-width: ${md}) {
              width: 130vw;
            }

            @media (min-width: ${lg}) {
              width: 90vw;
            }
          `}
        />
        <h1
          css={css`
            font-size: 56px;
            text-align: center;
            margin: 0 0 65px;

            @media (min-width: ${md}) {
              font-size: 118px;
              margin: 0 0 150px;
            }

            @media (min-width: ${lg}) {
              margin: 0 0 250px;
            }
          `}
        >
          clients
        </h1>
        <div
          css={css`
            padding: 0 20px;
          `}
        >
          <Slider slides={clients} />
        </div>
      </div>
    </section>
  )
}

export default Clients
