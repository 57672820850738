import React from "react"
import { css } from "@emotion/core"

const Button = ({ onClick, children, variant }) => {
  return (
    <button
      onClick={onClick}
      css={css`
        min-height: 100px;
        background-color: transparent;
        border: 10px solid var(--orange);
        font-weight: bold;
        font-size: 40px;
        letter-spacing: 0.11em;
        text-align: center;
        padding: 10px 20px;
        cursor: pointer;
        width: 100%;

        ${variant === "red" &&
        `
          background-color: var(--orange);
          border: 0;
          text-transform: uppercase;
        color: var(--whiter);
        `}

        ${variant === "white" &&
        `
          background-color: var(--whiter);
          border: 0;
          text-transform: uppercase;
        color: var(--orange);
        `}
      `}
    >
      {children}
    </button>
  )
}

export default Button
