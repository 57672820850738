import React from "react"
import scrollTo from "gatsby-plugin-smoothscroll"
import { css } from "@emotion/core"
import Button from "./button"
import { md } from "./layout"

const AboutUs = () => {
  return (
    <section
      id="about-us"
      css={css`
        padding: 175px 0;
      `}
    >
      <div className="container">
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 32px;
            height: 144px;
            background-color: var(--orange);
            border-radius: 10px 10px 0 0;
            @media (min-width: ${md}) {
              justify-content: flex-end;
            }
          `}
        >
          <h1
            css={css`
              margin: 0;
              font-size: 56px;

              @media (min-width: ${md}) {
                font-size: 118px;
              }
            `}
          >
            about us
          </h1>
        </div>
        <div
          css={css`
            min-height: 220px;
            border-left: 5px solid var(--orange);
            border-right: 5px solid var(--orange);
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <p
            css={css`
              font-size: 30px;
              text-align: center;
              margin: 0;
              width: 90%;

              @media (min-width: ${md}) {
                font-size: 40px;
              }
            `}
          >
            we believe every project is an empty canvas
          </p>
        </div>
        <div
          css={css`
            background-color: var(--orange);
            border-radius: 0 0 10px 10px;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <div
            css={css`
              flex: 1;
              padding: 0 5px;

              button {
                padding: 0 75px;
                border-top: 3px solid var(--orange);
                border-bottom: 3px solid var(--orange);
              }

              @media (min-width: ${md}) {
                flex: 0;
                button {
                  border-top: 0;
                  border-bottom: 0;
                }
              }
            `}
          >
            <Button onClick={() => scrollTo("#contact")} variant="white">
              Hire
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutUs
