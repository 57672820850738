import { useCallback, useState } from "react"

export default function useBoundingRect(elId) {
  const [rect, setRect] = useState({
    top: 0,
    left: 0,
    height: 0,
    width: 0,
  })
  const ref = useCallback((node) => {
    if (node !== null) {
      const sliderRect = document.getElementById(elId).getBoundingClientRect()
      const rect = node.getBoundingClientRect()
      setRect({
        top: rect.top - sliderRect.top,
        left: rect.left - sliderRect.left,
        height: rect.height,
        width: rect.width,
      })
    }
    // eslint-disable-next-line
  }, [])

  return [ref, rect, setRect]
}
