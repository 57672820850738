import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import AboutUs from "../components/about-us"
import Services from "../components/services"
import Clients from "../components/clients"
import Contact from "../components/contact"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero />
    <AboutUs />
    <Services />
    <Clients />
    <Contact />
  </Layout>
)

export default IndexPage
