import React, { useState } from "react"
import { css } from "@emotion/core"
import { md, lg } from "./layout"
import useBoundingRect from "../hooks/use-boundingrect"
import useLottie from "../hooks/use-lottie"
import animatedArrowLottie from "../lottie/arrowLottie.json"

const Slider = ({ slides }) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [opacity, setOpacity] = useState(0)
  const [contentOpacity, setContentOpacity] = useState(1)
  const [animatedArrow, arrowContainerRef] = useLottie({
    animation: animatedArrowLottie,
    loop: false,
    autoplay: false,
  })
  const [prevAnimatedArrow, prevArrowContainerRef] = useLottie({
    animation: animatedArrowLottie,
    loop: false,
    autoplay: false,
  })
  const [
    previousButton,
    previousButtonRect,
    setPreviousButtonRect,
  ] = useBoundingRect("slider")
  const [
    currentButton,
    currentButtonRect,
    setCurrentButtonRect,
  ] = useBoundingRect("slider")
  const [nextButton, nextButtonRect, setNextButtonRect] = useBoundingRect(
    "slider"
  )

  if (!slides) return null
  const previous = slides[currentSlide - 1] || slides[slides.length - 1]
  const current = slides[currentSlide]
  const next = slides[currentSlide + 1] || slides[0]

  const nextSlide = () => {
    let nextValue = currentSlide + 1
    if (nextValue > slides.length - 1) {
      nextValue = 0
    }

    setOpacity(1)
    setContentOpacity(0)
    if (previousButtonRect.width > 50) {
      setCurrentButtonRect(previousButtonRect)
      setNextButtonRect(currentButtonRect)
      setPreviousButtonRect(nextButtonRect)
    }

    setTimeout(() => {
      setOpacity(0)
      setContentOpacity(1)
      setCurrentSlide(nextValue)
    }, 300)
  }

  const previousSlide = () => {
    let previousValue = currentSlide - 1
    if (previousValue < 0) {
      previousValue = slides.length - 1
    }

    setOpacity(1)
    setContentOpacity(0)
    if (previousButtonRect.width > 50) {
      setCurrentButtonRect(nextButtonRect)
      setNextButtonRect(previousButtonRect)
      setPreviousButtonRect(currentButtonRect)
    }

    setTimeout(() => {
      setOpacity(0)
      setContentOpacity(1)
      setCurrentSlide(previousValue)
    }, 300)
  }

  return (
    <div
      id="slider"
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;

        @media (min-width: ${md}) {
          justify-content: space-evenly;
          position: relative;
        }

        @media (min-width: ${lg}) {
          justify-content: space-between;
        }

        div {
          position: absolute;
          border-radius: 50%;
          transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out,
            top 0.3s ease-in-out, left 0.3s ease-in-out;
          z-index: -1;

          @media (min-width: ${lg}) {
            background-color: var(--whiter);
          }
        }

        button {
          border: 0;
          border-radius: 50%;
          background-color: var(--whiter);
          max-width: 100%;
          cursor: pointer;
          height: 50px;
          width: 50px;

          h5 {
            font-size: 0;
            font-weight: bold;
            font-family: "Rubik", sans-serif;
            margin: 0;
            transition: opacity 0.3s ease-in-out;
          }

          &.small {
            display: none;
          }

          &.big {
            position: static;
            height: 322px;
            width: 322px;

            h5 {
              font-size: 30px;
            }

            span {
              font-weight: bold;
              font-size: 18px;
              transition: opacity 0.3s ease-in-out;
            }

            p {
              font-size: 18px;
              margin: 11px 0 18px;
              transition: opacity 0.3s ease-in-out;
            }
          }

          &.lottie {
            right: 0;
            position: absolute;
          }

          &.lottie:first-of-type {
            left: 0;
            transform: rotate(180deg);
          }

          @media (min-width: ${md}) {
            &.lottie {
              position: relative;
            }
          }

          @media (min-width: ${lg}) {
            height: 194px;
            width: 194px;
            &.lottie {
              display: none;
            }

            &.small {
              display: unset;
              position: static;

              h5 {
                font-size: 30px;
              }
            }
          }
        }
      `}
    >
      <div style={{ ...previousButtonRect, opacity }}></div>
      <div style={{ ...currentButtonRect, opacity }}></div>
      <div style={{ ...nextButtonRect, opacity }}></div>
      <button
        className="lottie"
        onClick={() => {
          prevAnimatedArrow.goToAndPlay(0, true)
          previousSlide()
        }}
        ref={prevArrowContainerRef}
        aria-label="Previous Client."
      ></button>
      <button className="small" ref={previousButton} onClick={previousSlide}>
        <h5 style={{ opacity: contentOpacity }}>{previous.title}</h5>
      </button>
      <button ref={currentButton} className="big">
        <h5 style={{ opacity: contentOpacity }}>{current.title}</h5>
        <p style={{ opacity: contentOpacity }}>{current.description}</p>
        <span style={{ opacity: contentOpacity }}>see more...</span>
      </button>
      <button
        className="lottie"
        onClick={() => {
          animatedArrow.goToAndPlay(0, true)
          nextSlide()
        }}
        ref={arrowContainerRef}
        aria-label="Next Client."
      ></button>
      <button className="small" ref={nextButton} onClick={nextSlide}>
        <h5 style={{ opacity: contentOpacity }}>{next.title}</h5>
      </button>
    </div>
  )
}

export default Slider
