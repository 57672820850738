import React from "react"
import scrollTo from "gatsby-plugin-smoothscroll"
import { css } from "@emotion/core"
import heroBg from "../images/HeroBg.svg"
import Button from "./button"
import { md, xl } from "./layout"

const Hero = () => {
  return (
    <section
      css={css`
        min-height: 80vh;
        background-image: url('${heroBg}');
        background-color: var(--whiter);
        background-position: bottom right;
        background-size: contain;
        background-repeat: no-repeat;
        padding: 80px 0;
      `}
    >
      <div className="container">
        <svg
          css={css`
            max-width: 100%;
          `}
          viewBox="0 0 1177 224"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0)">
            <path
              d="M136 97.7001C130.34 90.6001 122.647 85.6001 112.92 82.7001C120.653 79.3931 127.084 73.6337 131.22 66.3101C135.193 59.3529 137.288 51.4819 137.3 43.4701C137.303 38.0556 136.376 32.6809 134.56 27.5801C132.806 22.5327 130.176 17.8341 126.79 13.7001C123.421 9.61521 119.274 6.23995 114.59 3.77009C109.634 1.19518 104.114 -0.100728 98.53 9.25953e-05H0V137.7C3 132.82 5.35 128.63 6.8 125.7C13.03 113.13 27.9 94.3001 31.8 92.5301C33.15 91.7001 29.25 80.6901 38.57 67.1501C38.6942 66.9704 38.8616 66.825 39.0568 66.7271C39.252 66.6292 39.4687 66.5821 39.6869 66.59C39.9051 66.598 40.1179 66.6608 40.3054 66.7727C40.4929 66.8846 40.6493 67.0419 40.76 67.2301C41.76 68.8701 42.7 70.6301 43.64 72.3401C47.64 79.5801 51.39 86.4101 57.47 88.2501C59.25 88.7701 61.18 89.2001 63.23 89.6301C68.29 90.7401 74.61 92.1101 76.23 95.1501C77.5674 97.6156 79.1201 99.9581 80.87 102.15C85.42 107.85 90.99 111.91 96.87 113.75C97.22 113.88 97.57 113.98 97.92 114.08C100.678 114.712 103.501 115.018 106.33 114.99L108 115C108.657 115.034 109.281 115.296 109.765 115.742C110.249 116.187 110.562 116.788 110.65 117.44C111.22 122.03 110.89 130.12 106.75 136.56C102.61 143 95.24 146.47 85 146.47C70.8 146.47 63.19 148.1 61.46 153.24C59.5071 158.536 58.597 164.159 58.78 169.8H83.46C91.3759 169.838 99.2714 168.992 107 167.28C113.892 165.824 120.484 163.2 126.49 159.52C131.925 156.166 136.449 151.522 139.66 146C142.847 140.5 144.44 133.923 144.44 126.27C144.44 114.31 141.627 104.787 136 97.7001ZM87.17 63.1401C84.5847 64.8689 81.54 65.7816 78.43 65.7601H46.63V39.7001H79.87C82.7863 39.6578 85.6086 40.7309 87.76 42.7001C90 44.7001 91.1167 48.0101 91.11 52.6301C91.11 57.8768 89.7967 61.3801 87.17 63.1401Z"
              fill="#C9462F"
            />
            <path
              d="M283.26 128.54C281.98 126.873 281.34 123.873 281.34 119.54V95.1801C281.34 77.3268 275.923 63.9368 265.09 55.0101C254.257 46.0835 238.79 41.6201 218.69 41.6201C197.483 41.6201 178.59 46.9535 162.01 57.6201L175.15 85.8401C181.139 81.7633 187.557 78.3562 194.29 75.6801C200.637 73.3126 207.366 72.1363 214.14 72.2101C221.8 72.2101 227.34 73.6468 230.76 76.5201C234.2 79.3801 235.9 83.7801 235.9 89.6701V93.5001C229.2 90.3335 220.753 88.7401 210.56 88.7201C203.147 88.6376 195.765 89.6898 188.67 91.8401C182.472 93.6906 176.675 96.6856 171.58 100.67C166.902 104.365 163.067 109.015 160.33 114.31C157.589 119.718 156.196 125.708 156.27 131.77C156.217 137.283 157.36 142.742 159.62 147.77C161.777 152.628 164.912 156.988 168.83 160.58C172.916 164.272 177.667 167.153 182.83 169.07C188.408 171.165 194.322 172.219 200.28 172.18C208.428 172.209 216.464 170.278 223.71 166.55C231.304 162.589 237.923 156.991 243.09 150.16L244.09 154.72C245.36 160.44 248.09 164.81 252.34 167.72C256.59 170.63 262.73 172.15 270.87 172.15C273.793 172.116 276.711 171.913 279.61 171.54C282.877 171.14 286.34 170.543 290 169.75V131.54C286.76 131.21 284.53 130.22 283.26 128.54ZM235.9 126C235.868 127.691 235.282 129.325 234.23 130.65C233.019 132.261 231.569 133.677 229.93 134.85C227.548 136.351 224.971 137.515 222.27 138.31C219.564 139.162 216.746 139.603 213.91 139.62C210.083 139.778 206.32 138.597 203.27 136.28C201.939 135.279 200.864 133.977 200.134 132.48C199.404 130.984 199.039 129.335 199.07 127.67C199.07 123.53 200.91 120.09 204.57 117.38C208.23 114.67 212.87 113.31 218.45 113.31C221.343 113.361 224.223 113.696 227.05 114.31C230.05 114.91 233.006 115.709 235.9 116.7V126Z"
              fill="#C9462F"
            />
            <path
              d="M400.55 44.0002V62.6502C396.742 55.9795 391.093 50.5465 384.28 47.0002C377.427 43.4135 369.453 41.6201 360.36 41.6201C352.422 41.4977 344.55 43.0744 337.271 46.2445C329.993 49.4146 323.477 54.1047 318.16 60.0002C312.82 66.009 308.712 73.0082 306.07 80.6002C303.143 88.9731 301.691 97.7907 301.78 106.66C301.709 115.113 303.037 123.52 305.71 131.54C308.145 138.84 311.92 145.623 316.84 151.54C321.601 157.191 327.517 161.757 334.19 164.93C341.168 168.249 348.813 169.929 356.54 169.84C364.258 169.914 371.894 168.26 378.89 165C385.592 161.895 391.095 156.684 394.56 150.16V158.3C394.684 163.202 393.834 168.079 392.06 172.65C390.563 176.362 388.229 179.678 385.24 182.34C382.354 184.822 378.967 186.653 375.31 187.71C371.467 188.85 367.478 189.416 363.47 189.39C356.34 189.444 349.283 187.946 342.79 185C336.696 182.299 331.083 178.625 326.17 174.12L302.25 199C309.721 206.695 318.67 212.8 328.56 216.95C338.447 221.083 350.083 223.153 363.47 223.16C374.098 223.287 384.678 221.714 394.81 218.5C403.609 215.724 411.806 211.314 418.97 205.5C425.608 199.98 430.916 193.035 434.5 185.18C438.257 176.755 440.133 167.614 440 158.39V44.0002H400.55ZM394.55 121C392.001 124.84 388.71 128.131 384.87 130.68C381.243 132.9 377.051 134.025 372.8 133.92C365.62 133.92 359.72 131.527 355.1 126.74C350.48 121.953 348.163 115.653 348.15 107.84C348.14 104.16 348.71 100.502 349.84 97.0002C350.85 93.7703 352.473 90.7654 354.62 88.1502C356.657 85.7071 359.137 83.6713 361.93 82.1502C364.689 80.644 367.787 79.8662 370.93 79.8902C375.945 79.9646 380.789 81.726 384.68 84.8902C389.198 88.4193 392.634 93.145 394.6 98.5302L394.55 121Z"
              fill="#C9462F"
            />
            <path
              d="M585.72 128.54C584.43 126.87 583.8 123.88 583.8 119.54V44H538.36V121C534.7 126.42 531.02 129.93 527.36 131.53C523.973 133.059 520.306 133.873 516.59 133.92C514.659 134.008 512.735 133.641 510.972 132.849C509.209 132.057 507.657 130.862 506.44 129.36C503.96 126.34 502.723 122.04 502.73 116.46V44H457.29V126.74C457.29 141.573 460.623 152.857 467.29 160.59C473.957 168.323 483.687 172.19 496.48 172.19C507.013 172.19 516.347 170.473 524.48 167.04C532.861 163.393 540.112 157.568 545.48 150.17L546.48 154.73C548.873 166.357 557.8 172.177 573.26 172.19C579.699 172.012 586.106 171.208 592.39 169.79V131.54C589.21 131.21 587 130.22 585.72 128.54Z"
              fill="#C9462F"
            />
            <path
              d="M716.92 116.83C715.173 112.708 712.423 109.089 708.92 106.3C704.387 102.73 699.361 99.8335 694 97.7002C686.519 94.652 678.834 92.1325 671 90.1602C663.513 88.2535 658.49 86.5335 655.93 85.0002C653.37 83.4902 652.1 81.5202 652.1 79.1302C652.04 78.1486 652.255 77.1698 652.722 76.3041C653.188 75.4383 653.887 74.7201 654.74 74.2302C656.795 73.125 659.109 72.593 661.44 72.6902C666.545 72.7924 671.582 73.886 676.27 75.9102C683.121 78.9321 689.682 82.5739 695.87 86.7902L713.1 59.5502C704.64 52.2169 696.227 47.3502 687.86 44.9502C679.263 42.5311 670.37 41.3261 661.44 41.3702C654.413 41.2993 647.423 42.3872 640.75 44.5902C634.778 46.5696 629.251 49.6984 624.48 53.8002C619.991 57.7169 616.368 62.5257 613.84 67.9202C611.258 73.4571 609.946 79.5011 610 85.6102C609.897 89.8834 610.712 94.129 612.39 98.0602C614.232 101.855 616.979 105.138 620.39 107.62C624.896 110.898 629.803 113.586 634.99 115.62C642.3 118.548 649.768 121.062 657.36 123.15C664.53 125.23 669.36 127.05 671.82 128.65C674.28 130.25 675.53 132.16 675.53 134.39C675.53 138.39 672.34 140.39 665.96 140.39C659.887 140.39 653.22 138.954 645.96 136.08C638.012 132.839 630.438 128.749 623.37 123.88L607.83 151.14C615.531 158.222 624.664 163.566 634.61 166.81C645.002 170.376 655.913 172.197 666.9 172.2C674.294 172.299 681.664 171.329 688.78 169.32C694.776 167.64 700.383 164.796 705.28 160.95C709.713 157.422 713.272 152.918 715.68 147.79C718.153 142.307 719.38 136.344 719.27 130.33C719.373 125.72 718.574 121.134 716.92 116.83V116.83Z"
              fill="#C9462F"
            />
            <path
              d="M736.6 169.77V1.07007H801.47C815.397 1.07007 827.673 3.28673 838.3 7.72007C848.236 11.6623 857.202 17.7059 864.584 25.4368C871.966 33.1676 877.59 42.403 881.07 52.5101C884.756 63.0047 886.604 74.057 886.53 85.1801C886.53 98.0134 884.51 109.617 880.47 119.99C876.682 129.981 870.812 139.052 863.25 146.6C855.585 154.106 846.441 159.933 836.4 163.71C825.232 167.876 813.388 169.931 801.47 169.77H736.6ZM839.48 85.1801C839.547 79.1401 838.666 73.1271 836.87 67.3601C835.313 62.3172 832.77 57.6335 829.39 53.5801C826.104 49.7531 822.004 46.7089 817.39 44.6701C812.373 42.4764 806.945 41.3819 801.47 41.4601H782.93V129.38H801.47C807.033 129.474 812.548 128.337 817.62 126.05C822.273 123.914 826.378 120.743 829.62 116.78C832.971 112.642 835.475 107.885 836.99 102.78C838.698 97.0716 839.538 91.1384 839.48 85.1801Z"
              fill="#352926"
            />
            <path
              d="M967 172.15C956.073 172.15 946.33 170.447 937.77 167.04C929.714 163.953 922.392 159.219 916.27 153.14C910.546 147.373 906.058 140.5 903.08 132.94C900.061 125.3 898.527 117.155 898.56 108.94C898.526 100.193 900.028 91.5074 903 83.2802C905.794 75.3925 910.224 68.1849 916 62.1302C922.062 55.9203 929.341 51.0282 937.38 47.7602C945.86 44.1935 955.723 42.4102 966.97 42.4102C978.05 42.4102 987.87 44.1935 996.43 47.7602C1004.52 50.9809 1011.86 55.8276 1018 62.0002C1023.73 67.9123 1028.16 74.9606 1031 82.6902C1033.94 90.5547 1035.43 98.8853 1035.4 107.28C1035.4 109.66 1035.28 112.07 1035.04 114.53C1034.8 116.99 1034.53 119.16 1034.21 121.06H946.56C947.04 127.727 949.497 132.52 953.93 135.44C958.211 138.322 963.259 139.851 968.42 139.83C973.252 139.851 978.019 138.713 982.32 136.51C986.68 134.29 989.573 131.2 991 127.24L1029.25 138.17C1024.18 148.17 1016.38 156.327 1005.85 162.64C995.317 168.953 982.367 172.123 967 172.15ZM987 94.0002C986.2 88.0002 983.98 83.2502 980.34 79.7502C976.7 76.2502 972.033 74.5068 966.34 74.5202C960.64 74.5202 955.973 76.2635 952.34 79.7502C948.707 83.2368 946.463 87.9868 945.61 94.0002H987Z"
              fill="#352926"
            />
            <path
              d="M1085.2 169.77L1043.14 44.77H1089.47L1111.81 134.35L1134.38 44.77H1176.44L1134.38 169.77H1085.2Z"
              fill="#352926"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="1176.44" height="223.13" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <nav
          css={css`
            display: grid;
            margin-top: 20px;
            gap: 20px;
            grid-template-columns: 1fr;
            @media (min-width: ${md}) {
              grid-template-columns: repeat(12, 1fr);
            }
          `}
        >
          <div
            css={css`
              grid-column: 1/2;
              @media (min-width: ${md}) {
                grid-column: 1/2;
              }

              @media (min-width: ${xl}) {
                grid-column: 1/3;
              }
            `}
          >
            <Button onClick={() => scrollTo("#about-us")}>us</Button>
          </div>
          <div
            css={css`
              grid-column: 1/2;
              @media (min-width: ${md}) {
                grid-column: 1/-1;
              }
              @media (min-width: ${xl}) {
                grid-column: 1/5;
              }
            `}
          >
            <Button onClick={() => scrollTo("#services")}>services</Button>
          </div>
          <div
            css={css`
              grid-column: 1/2;
              @media (min-width: ${md}) {
                grid-column: -1/2;
              }
              @media (min-width: ${xl}) {
                grid-column: 1/4;
              }
            `}
          >
            {" "}
            <Button onClick={() => scrollTo("#clients")}>clients</Button>
          </div>
          <div
            css={css`
              grid-column: 1/2;
              @media (min-width: ${md}) {
                grid-column: 1/10;
              }
              @media (min-width: ${xl}) {
                grid-column: 4/8;
              }
            `}
          >
            <Button variant="red" onClick={() => scrollTo("#contact")}>
              hire
            </Button>
          </div>
        </nav>
      </div>
    </section>
  )
}

export default Hero
