import React from "react"
import { css } from "@emotion/core"

const inputCSS = css`
  background: var(--whiter);
  border: 2px solid var(--whiter);
  box-sizing: border-box;
  border-radius: 12px;
  font-size: 35px;
  padding: 10px 52px 10px 10px;
  width: 100%;
  margin-top: 16px;
  font-family: inherit;
  transition: border-color 0.15s ease-in-out;

  &:focus-within,
  &:focus {
    border-color: var(--orange);
  }
`

const Input = ({ label, type, name, icon }) => (
  <label
    htmlFor={`id-${label}-${type}`}
    css={css`
      color: var(--whiter);
      font-weight: bold;
      font-size: 35px;
      line-height: 23px;
      display: flex;
      flex-direction: column;
      width: 100%;
      font-weight: bold;

      span {
        position: relative;
        align-self: flex-start;
        z-index: 2;
      }

      span::after {
        content: "";
        position: absolute;
        width: 100%;
        background-color: var(--orange);
        height: 5px;
        bottom: -2px;
        z-index: -1;
        transition: transform 0.15s ease-in-out;
        transform: scaleY(0);
        transform-origin: 100% 100%;
        left: 0;
      }

      &:focus-within {
        span::after {
          transform: scaleY(1);
        }
      }
    `}
  >
    <span id={`label-${label}-${type}`}>{label}:</span>
    <div
      css={css`
        position: relative;
      `}
    >
      {type !== "textarea" ? (
        <input
          css={inputCSS}
          aria-labelledby={`label-${label}-${type}`}
          id={`id-${label}-${type}`}
          type={type}
          name={name}
        />
      ) : (
        <textarea
          css={inputCSS}
          aria-labelledby={`label-${label}-${type}`}
          id={`id-${label}-${type}`}
          name={name}
        ></textarea>
      )}
      {icon && (
        <img
          css={css`
            position: absolute;
            top: ${type === "textarea" ? "26px" : "36px"};
            right: 14px;
          `}
          src={icon}
          alt="Icon."
        />
      )}
    </div>
  </label>
)

export default Input
